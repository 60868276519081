import { Component, Mixins } from 'vue-property-decorator';

import headerBg from '~/assets/images/header-bg-default.jpg';
import Header from '~/components/templates/Header';
import HeadManagement from '~/mixins/HeadManagement';
import voucher1 from '~/assets/voucher/voucher-1.png';
import voucher2 from '~/assets/voucher/voucher-2.png';
import voucher3 from '~/assets/voucher/voucher-3.png';

interface Voucher {
  src: string;
  downloadName: string;
}

const vouchers: Voucher[] = [
  {
    src: voucher1,
    downloadName: 'elixir-tours-1.png',
  },
  {
    src: voucher2,
    downloadName: 'elixir-tours-2.png',
  },
  {
    src: voucher3,
    downloadName: 'elixir-tours-3.png',
  },
];

@Component
export default class GiftVoucher extends Mixins(HeadManagement) {
  public title() {
    return this.$t('app.giftVoucher.pageTitle');
  }

  public render() {
    // Change colors based on the breakpoint
    const stripeColor =
      ['lg', 'xl'].indexOf(this.$vuetify.breakpoint.name.toString()) > -1
        ? 'rgba(255, 255, 255, 0.8)'
        : 'rgba(198, 197, 222, 0.9)';

    return (
      <div>
        <background-wrapper imgUrl={headerBg} class='header-bg-z-index'>
          <background-wrapper stripeColor={stripeColor}>
            <v-container class='px-0 pt-0'>
              <Header />
            </v-container>
          </background-wrapper>
        </background-wrapper>
        <v-container class='custom-content'>
          <v-card>
            <v-card-title>Dárkové poukazy Elixír tours</v-card-title>
            <v-card-text>
              <strong>
                Darujte víc než jen dárek – darujte nezapomenutelný zážitek.
              </strong>
              <p>
                Každý z nás si zaslouží chvíle klidu, radosti a objevování
                nových míst. S našimi poukazy darujete svým blízkým nejen cestu
                do exotického ráje, ale také vzácné okamžiky pohody a štěstí,
                které si uchovají navždy. Ať už jde o svatební cestu, rodinnou
                dovolenou, nebo jen útěk z každodenního shonu, darujete zážitek,
                který je naplní energií a radostí z přítomného okamžiku.
              </p>

              <h2>Jak to funguje?</h2>
              <h3>1. Stáhněte si poukaz</h3>
              <p>
                Vyberte si z našich dárkových poukazů, stáhněte si ho zdarma,
                napište věnování a darujte.
              </p>

              <h3>2. Platba za poukaz</h3>
              <p>
                Po stažení poukazu odešlete částku dle zvolené hodnoty poukazu
                na náš bankovní účet:
              </p>
              <p>
                <strong>Číslo účtu:</strong> KB 107-9161320217/0100
              </p>
              <p>
                <strong>Variabilní symbol:</strong> Datum zakoupení (ve formátu
                den měsíc rok např. 05122024)
              </p>
              <p>
                <strong>Do zprávy pro příjemce:</strong> Uveďte jméno, pod
                kterým chcete poukaz registrovat.
              </p>

              <h3>3. Potvrzení platby</h3>
              <p>
                Jakmile obdržíme platbu, zašleme vám potvrzovací e-mail. V něm
                bude uvedeno, že poukaz je aktivní a připraven k použití.
              </p>

              <h3>4. Uplatnění poukazu</h3>
              <p>
                Obdarovaný může poukaz uplatnit při rezervaci zájezdu. Stačí při
                rezervaci uvést své jméno a datum zakoupení, které byly uvedeny
                při platbě.
              </p>

              <h2>Podmínky použití</h2>

              <ul>
                <li>Poukaz je platný 12 měsíců od data potvrzení platby.</li>
                <li>Lze ho využít na jakýkoliv zájezd z naší nabídky.</li>
                <li>Poukaz je přenosný – můžete ho darovat komukoli.</li>
              </ul>

              <br />

              <p>Elixír tours - dovolená, která vám zůstane v srdci. </p>
            </v-card-text>
          </v-card>

          <section>
            <v-row>
              {vouchers.map((voucher) => (
                <v-col cols='4' sm='12' md='4'>
                  <a href={voucher.src} download={voucher.downloadName}>
                    <v-img src={voucher.src} alt={voucher.downloadName} />
                  </a>
                </v-col>
              ))}
            </v-row>
          </section>
        </v-container>
      </div>
    );
  }
}
